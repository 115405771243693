import { Route } from "src/common/routing";
import { CreditorType } from "@gocardless/api/dashboard/types";
import { SetupPages } from "src/components/routes/Setup/common/config/types";
import { CreditorDetailUpdateRequestBody } from "@gocardless/api/dashboard/creditor-detail";

import { MerchantOnboardingSetupEvents } from "../common/constants/MerchantOnboardingSetupEvents";
import { useSetupData } from "../common/hooks/useSetupData";
import { UseSetupPage, UseSetupPageCallbacks } from "../routing/types";
import { useConfig } from "../common/config/useConfig";
import { useComplete } from "../common/validators/useComplete";
import {
  companyBuilder,
  individualBuilder,
  charityBuilder,
  businessDetailsBuilder,
  businessDetailsEditedEventBuilder,
  partnershipBuilder,
  trustBuilder,
} from "../common/builders/details";
import { useSegmentForSetup } from "../common/hooks/useSegmentForSetup";

import { BusinessDetailsConfig } from "./types";
import { BusinessDetailsFormVariant } from "./BusinessDetailsForm";

type SubmitBusinessDetails = (
  data: BusinessDetailsConfig,
  setRegisteredBusinessSearchId: null | string,
  setRegisteredBusinessSearchProvider: null | string,
  enterCompanyDetailsManually: boolean,
  variant?: BusinessDetailsFormVariant
) => void;

export interface UseBusinessDetails extends UseSetupPage {
  businessDetails: BusinessDetailsConfig;
  achUplift?: boolean;
  submitBusinessDetails: SubmitBusinessDetails;
}

export function useBusinessDetails({
  onSuccess = () => {},
  onError = () => {},
}: UseSetupPageCallbacks = {}): UseBusinessDetails {
  const { sendEvent } = useSegmentForSetup();
  const { getAttrsByCreditorTypeAndGeo } = useConfig(
    SetupPages.BUSINESS_DETAILS
  );

  const { creditor, creditorDetails, updateCreditor, updateCreditorDetail } =
    useSetupData(onSuccess, onError);

  const { isComplete } = useComplete(SetupPages.BUSINESS_DETAILS);

  const creditor_type =
    creditorDetails?.creditor_type ??
    creditor?.creditor_type ??
    CreditorType.Company;

  // country_code isn't a field shown on the business details page
  // but we need to get it to display the address input correctly
  const attrs = [
    ...getAttrsByCreditorTypeAndGeo(creditor_type, creditor?.geo),
    "country_code",
  ];

  const businessDetails = businessDetailsBuilder(
    creditor_type,
    creditor,
    creditorDetails,
    attrs
  );

  const sendEvents = (data: BusinessDetailsConfig) => {
    const prev = creditorDetails?.detail;
    sendEvent(
      MerchantOnboardingSetupEvents.BusinessInformationDetailsEdited,
      businessDetailsEditedEventBuilder(data, prev)
    );
  };

  const shouldUpdateCreditor = (data: BusinessDetailsConfig) =>
    data.creditor_type !== CreditorType.Individual &&
    creditor &&
    data.trading_name !== creditor?.name;

  const submitBusinessDetails: SubmitBusinessDetails = (
    data,
    registeredBusinessSearchId,
    registeredBusinessSearchProvider,
    enterCompanyDetailsManually,
    variant
  ) => {
    const { creditor_type: submit_creditor_type = creditor_type } = data;
    const put: CreditorDetailUpdateRequestBody = {
      creditor_type: submit_creditor_type,
      detail:
        submit_creditor_type === CreditorType.Company
          ? companyBuilder(
              data,
              registeredBusinessSearchId,
              registeredBusinessSearchProvider
            )
          : submit_creditor_type === CreditorType.Individual
            ? individualBuilder(data, variant)
            : submit_creditor_type === CreditorType.Charity
              ? charityBuilder(data)
              : submit_creditor_type === CreditorType.Partnership
                ? partnershipBuilder(data)
                : submit_creditor_type === CreditorType.Trust
                  ? trustBuilder(data)
                  : {},
    };
    updateCreditorDetail(put);
    // Trading name is currently stored and retrieved from creditor
    // Need to update creditor if the trading name changes
    if (shouldUpdateCreditor(data)) updateCreditor({ name: data.trading_name });
    // If details have been entered manually send an Segment event
    if (enterCompanyDetailsManually) sendEvents(data);
  };

  return {
    loaded: !!creditorDetails,
    completed: isComplete(
      {
        ...businessDetails,
        creditor_type: creditorDetails?.creditor_type,
      },
      // Exclude certain fields from business details completeness check
      ["personDetails", "personAddress"]
    ),
    route: Route.BusinessDetails,
    businessDetails,
    achUplift: creditor?.ach_uplift_initiated,
    submitBusinessDetails,
  };
}
