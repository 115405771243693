import { useBankAuthorisationSelf } from "@gocardless/api/dashboard/bank-authorisation";
import {
  useCreditorSelf,
  useCreditorUpdate,
} from "@gocardless/api/dashboard/creditor";
import {
  useCreditorBankAccountCreate,
  useCreditorBankAccountDisable,
  useCreditorBankAccountList,
} from "@gocardless/api/dashboard/creditor-bank-account";
import { useRequiredActionList } from "@gocardless/api/dashboard/required-action";
import {
  BankAuthorisationResource,
  CreditorBankAccountCreateRequestBody,
  CreditorBankAccountCreateResponseBody,
  CreditorBankAccountDisableResponseBody,
  CreditorBankAccountResource,
  SchemeIdentifier,
} from "@gocardless/api/dashboard/types";
import { Error, getErrorsFromErrorResponse } from "@gocardless/api/utils/error";
import { isEmpty, keyBy, omit } from "lodash";
import { Currency } from "src/common/currencies";
import { useToastNotification } from "src/common/notifications/useToastNotification";
import { Route, useRouterQuery } from "src/common/routing";
import {
  OrganisationType,
  useOrganisationWithType,
} from "src/queries/organisationType";
import { useOptimizelyVariation } from "src/technical-integrations/optimizely/useOptimizelyVariation";
import { OptimizelyFlag } from "src/technical-integrations/optimizely/constants";

import { UseSetupPage } from "../../routing/types";

export interface UseBankDetails extends UseSetupPage {
  primaryAccount?: CreditorBankAccountResource;
  additionalAccounts: Record<string, CreditorBankAccountResource>;
  submit?: (
    b?: CreditorBankAccountCreateRequestBody | undefined
  ) => Promise<CreditorBankAccountCreateResponseBody | void>;
  disableAccount: (body?: {}) => Promise<CreditorBankAccountDisableResponseBody | void>;
  refreshSetup: () => Promise<Array<boolean>>;
  primaryCurrency: Currency | string;
  additionalCurrencies: Currency[];
  schemeIdentifiers: SchemeIdentifier[];
  bankAuthorisation?: BankAuthorisationResource;
}

interface BankSetupProps {
  currency: Currency;
  bankDetails: CreditorBankAccountCreateRequestBody;
  onSubmit: (bankDetails?: CreditorBankAccountResource) => void;
  accountId: string;
  onAccountDisabled: () => void;
}

export const useBankDetails = (props?: BankSetupProps): UseBankDetails => {
  const { organisation, organisationType } = useOrganisationWithType();
  const isPaymentProvider =
    organisationType === OrganisationType.PaymentProvider;
  const primaryCreditorId = organisation?.links?.primary_creditor;

  const { data: creditorResponse, revalidate: refreshCreditor } =
    useCreditorSelf(primaryCreditorId || null);
  const creditor = creditorResponse?.creditors;
  const {
    data: creditorBankAccountsResponse,
    revalidate: refreshBankAccounts,
  } = useCreditorBankAccountList({
    creditor: creditor?.id,
    default_payout_account: true,
  });
  const { revalidate: refreshRequiredActions } = useRequiredActionList();
  const { triggerErrorNotification, triggerSuccessNotification } =
    useToastNotification();
  const bankAuthorisationId = useRouterQuery("bankAuth").item;
  const { data: bankAuthorisation } = useBankAuthorisationSelf(
    bankAuthorisationId,
    !!bankAuthorisationId
  );

  const { geo } = creditor || {};
  const supportedGeos = ["GB"];

  const { isVariationOn } = useOptimizelyVariation({
    flag: OptimizelyFlag.TURBO_GROWTH_AIS_VERIFICATION_IN_THE_US,
  });
  const supportedInTheUs = geo === "US" && isVariationOn;

  const isAISFlowEnabled =
    geo && (supportedGeos.includes(geo) || supportedInTheUs);

  const refreshSetup = () =>
    Promise.all([
      refreshBankAccounts(),
      refreshRequiredActions(),
      refreshCreditor(),
    ]);

  const notifyErrors = (errors: Error[]) =>
    errors?.forEach(({ message, field }) =>
      triggerErrorNotification({
        message: `${field?.replace(/_/g, " ")} ${message}`,
      })
    );

  const [updateDefaultBank] = useCreditorUpdate(creditor?.id ?? "", {
    onError: async (error) => {
      const errors = await getErrorsFromErrorResponse(error);
      notifyErrors(errors);
    },
    onSuccess() {
      triggerSuccessNotification({ message: "Bank details updated" });

      const { account_holder_name, country_code } = props?.bankDetails ?? {};
      const updatedBank: CreditorBankAccountResource = {
        currency: props?.bankDetails?.currency,
        account_holder_name,
        country_code,
      };

      props?.onSubmit(updatedBank);
    },
  });

  const [submit] = useCreditorBankAccountCreate({
    onError: async (error) => {
      const errors = await getErrorsFromErrorResponse(error);

      // This account already exists and can't be re-created, so it is updated
      // to be the default {currency} payout account for the creditor.
      if (errors[0]?.reason === "bank_account_exists") {
        const defaultCurrencyKey = `default_${props?.currency.toLowerCase()}_payout_account`;
        updateDefaultBank({
          links: {
            [defaultCurrencyKey]: errors[0]?.links?.creditor_bank_account,
          },
        });
        return;
      }
      notifyErrors(errors);
    },
    onSuccess(response) {
      triggerSuccessNotification({ message: "Bank account added" });
      props?.onSubmit(response?.creditor_bank_accounts);
    },
  });

  const [disableAccount] = useCreditorBankAccountDisable(
    props?.accountId as string,
    {
      onSuccess: () => {
        triggerSuccessNotification({
          message: `${props?.currency} account disabled`,
        });
        props?.onAccountDisabled();
      },
      onError: async (error) => {
        const errors = await getErrorsFromErrorResponse(error);
        notifyErrors(errors);
      },
    }
  );

  const { fx_payout_currency: fxPayoutCurrency } = creditor ?? {};
  const bankAccounts = keyBy(
    creditorBankAccountsResponse?.creditor_bank_accounts ?? [],
    "currency"
  ) as Record<Currency, CreditorBankAccountResource>;

  const domesticCurrency = organisation?.domestic_currency;
  const primaryCurrency = fxPayoutCurrency || domesticCurrency || Currency.Gbp;

  const primaryAccount = bankAccounts[primaryCurrency as Currency];
  const additionalAccounts = omit(bankAccounts, primaryCurrency);
  const schemeIdentifiers = creditor?.scheme_identifiers ?? [];

  const additionalCurrencies: Currency[] = schemeIdentifiers
    .filter((scheme) => scheme.currency && scheme.currency !== primaryCurrency)
    .map((scheme) => scheme.currency as Currency);

  const manualQuery = useRouterQuery("manual").item;
  const useManualBankDetails =
    primaryAccount ||
    (window.location.pathname === "/setup/bank-details" && manualQuery);

  const completed = !isEmpty(bankAccounts);

  return {
    submit,
    disableAccount,
    loaded:
      !!creditorBankAccountsResponse &&
      (!bankAuthorisationId || !!bankAuthorisation),
    completed,
    skip: !!isAISFlowEnabled && !useManualBankDetails && !isPaymentProvider,
    route: Route.BankDetails,
    refreshSetup,
    primaryAccount,
    additionalAccounts,
    primaryCurrency,
    additionalCurrencies,
    schemeIdentifiers: creditor?.scheme_identifiers ?? [],
    bankAuthorisation: bankAuthorisation?.bank_authorisations,
  };
};
